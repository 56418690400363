

import { Component } from "vue-property-decorator";
import AddComment from "@/views/AddComment.vue";
import { TitleReceived } from "@/models/tasks.model";
import { BASE_API_URL,EventBus } from "../../../../../config";
import Axios from "axios";
import { ALLOWED_FILE_EXTENSIONS } from "@/constants";
import PipeLineSocketMixin from "@/mixins/PipeLineSocketMixin"
import CommonMixin from "@/mixins/common.mixin";
import { mixins } from "vue-class-component";


@Component({components:{AddComment}})
export default class HoiComponent extends mixins(PipeLineSocketMixin, CommonMixin) {
public allowedFileExtensions = ALLOWED_FILE_EXTENSIONS;
public titleReceived = new TitleReceived();
public pipeLineHeaderData:any ={}
public loanTxnId  = null
public taskNameForRoom = 'title-received';

 private playWeLearnVideo() {
    EventBus.$emit("openWeLearn", { taskName: "Title Received", videoUrl: 'https://wemlo.s3.amazonaws.com/Common/taskdemo/TitleReceived.mp4' });
  }
public async fetchTitleReceivedTaskDetail() {
    try {
      this.$store.state.wemloLoader = true;
      let response = await Axios.post(
        BASE_API_URL + "additionalTask/fetchTitleReceivedTaskDetails",
        { loanTxnId: this.loanTxnId });

      this.pipeLineHeaderData = response.data.pipeLineHeaderData;
      this.titleReceived = response.data.titleReceived;
      this.$store.state.wemloLoader = false;
    } catch (error) {
      console.log(error);
      this.$store.state.wemloLoader = false;
    }
  }


public async submit(ifSave){
    try {
       ifSave = ifSave  == 'Save'?true:false
         let validComment = await this.$refs.comment['validateComment'](ifSave);


      if ((ifSave ? false : !await this.$validator.validateAll() ) || !validComment)
      return;
       this.$store.state.wemloLoader = true;
      let formData = new FormData();

       formData.append("loanTxnId", this.loanTxnId);

        if (
        this.titleReceived.titleReportInvoice &&
        !this.titleReceived.titleReportInvoice.hasOwnProperty("path")
      )
        this.titleReceived.titleReportInvoice.forEach(file => {
          formData.append("titleReportInvoice", file);
        });

        /**
       * Appending comment data and comment attachments here in formdata
       */
      let commentObj: any = {
        comment: this.titleReceived.commentData.comment,
        showCommentToBroker: this.titleReceived.commentData.showCommentToBroker
      }
      if (this.titleReceived.commentData.commentAttachment.length > 0 && !this.titleReceived.commentData.commentAttachment[0].hasOwnProperty("path"))
        this.titleReceived.commentData.commentAttachment.forEach(file => {
          formData.append("commentAttachment", file);
        });
      else if (this.titleReceived.commentData.commentAttachment.length > 0)
        commentObj.commentAttachment = this.titleReceived.commentData.commentAttachment;
      formData.append("commentData", JSON.stringify(commentObj));

      /**
       * Merging the save or complete option here with wemlo invoice and options
       */
      formData.append("ifSave", ifSave);
      formData.append("options", JSON.stringify(this.titleReceived.options));

      let response = await Axios.post(BASE_API_URL + "additionalTask/titleReceived", formData);

      this.$snotify[response.data.status == 200 ? "success" : response.data.status == 403 ? "info": "error"](response.data.message);

      if (response.data.status == 200) {
        this.saveEvent();
      }

      EventBus.$emit('closeWeLearn',false); // Closing learn more video modal;     

      if (!ifSave) {
        this.$router.push({ path: "dashboard" });
      }
    } catch (error) {
      console.log(error)
      const infectedFiles = this.checkResponseIfFileInfected(error);
      infectedFiles.forEach(filename => {
        this.removeInfectedFile(filename)
      })
    } finally {
      this.$store.state.wemloLoader = false;
    }
  }
  public removeInfectedFile(filename: string): void {
    // check if comment attachment contains infected filename
    if (
      this.titleReceived.commentData.commentAttachment.length > 0 &&
      !this.titleReceived.commentData.commentAttachment[0].hasOwnProperty("path")
    )
    {
      this.titleReceived.commentData.commentAttachment = this.titleReceived.commentData.commentAttachment.filter(file => (file.name !== filename));
    }
    if (
      this.titleReceived.titleReportInvoice &&
      !this.titleReceived.titleReportInvoice.hasOwnProperty("path")
    ) {
      this.titleReceived.titleReportInvoice = this.titleReceived.titleReportInvoice.filter(file => (file.name !== filename));
    }
  }

  async uploadTitleReportInvoice(event) {
    if (event.target.files.length > 0) {
      Object.values(event.target.files).forEach(element => {
        this.titleReceived.titleReportInvoice.push(element);
      });
    }
  }
  public viewTitleReportInvoice(file, viewFileName) {
    this.$refs.comment["displayCommentAttachment"](file, true, viewFileName);
  }
  public deleteDoc(obj) {
    this.titleReceived[obj.deleteData].splice(obj.index, 1);
  }

  public async deleteFile(obj) {
    let index = this.titleReceived[obj.deleteData].findIndex(
      doc => doc.path == obj.files
    );
    this.titleReceived[obj.deleteData].splice(index, 1);
    await this.deleteDisplayFromTaskCollection(index, obj.deleteData);
  }
  public async deleteDisplayFromTaskCollection(index, categoryName) {
    try {
      let response = await Axios.post(
        BASE_API_URL + "wemloprocessor/deleteDocumentByCategoryName",
        { loanTxnId: this.loanTxnId, index: index, categoryName: categoryName });
    } catch (error) {
      console.log(error);
    }
  }

async mounted(){
   this.loanTxnId = this.$route.query.id;
  await this.fetchTitleReceivedTaskDetail()
}
}
