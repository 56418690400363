

import { Component } from "vue-property-decorator";
import AddComment from "@/views/AddComment.vue";
import { Appraisal } from "@/models/tasks.model";
import { BASE_API_URL,EventBus } from "../../../../../config";
import Axios from "axios";

import DisplayFiles from "@/views/DisplayDocument.vue";
import CreditAuthorization from "@/views/CreditAuthorizationInTask.vue";
import PipeLineSocketMixin from "@/mixins/PipeLineSocketMixin"
import { mixins } from "vue-class-component";

@Component({ components: { AddComment,DisplayFiles,CreditAuthorization } })
export default class AppraisalComponent extends mixins(PipeLineSocketMixin) {
  public appraisal:any= new Appraisal();
  public pipeLineHeaderData: any = {};
  public loanTxnId = null;
  private showVendor = 'vendors';
  private wemloVendorsOption  =false
  public taskNameForRoom = 'appraisal';


  //to display credit authoria=zation 
  public dotLoader = false;
  public fileType: String = "jpg";
  public fileToDisplay: String = null;
  public fileIndex: any = null;
  public fileArray: Array<any> = null;
  public showNext: Boolean = null;
  public showPrev: Boolean = null;

  private playWeLearnVideo() {
    EventBus.$emit("openWeLearn", { taskName: "Appraisal", videoUrl: 'https://wemlo.s3.amazonaws.com/Common/taskdemo/AppraisalOrder.mp4' });
  }
  public async fetchAppraisalTaskDetail() {
    try {
      this.$store.state.wemloLoader = true;
      let response = await Axios.post(
        BASE_API_URL + "additionalTask/fetchAppraisalTaskDetails",
        { loanTxnId: this.loanTxnId });
      this.pipeLineHeaderData = response.data.pipeLineHeaderData;
      this.appraisal = response.data.appraisal;
      if(this.appraisal.creditAuthorization.documents.length>0) await this.documentDisplay(this.appraisal.creditAuthorization.documents)
      this.showVendor = this.appraisal.options.isWemloVendor ?'wemloVendors':'vendors'
      this.$store.state.wemloLoader = false;
    } catch (error) {
      console.log(error);
      this.$store.state.wemloLoader = false;
    }
  }
  public async submit(ifSave) {
    try {
      ifSave = ifSave == "Save" ? true : false;
      let validComment = await this.$refs.comment["validateComment"](ifSave);

      if (
        (ifSave ? false : !(await this.$validator.validateAll())) ||
        !validComment 
      )
        return;
      this.$store.state.wemloLoader = true;
      let formData = new FormData();

      formData.append("loanTxnId", this.loanTxnId);

      /**
       * Appending comment data and comment attachments here in formdata
       */
      let commentObj: any = {
        comment: this.appraisal.commentData.comment,
        showCommentToBroker: this.appraisal.commentData.showCommentToBroker
      };
      if (
        this.appraisal.commentData.commentAttachment.length > 0 &&
        !this.appraisal.commentData.commentAttachment[0].hasOwnProperty("path")
      )
        this.appraisal.commentData.commentAttachment.forEach(file => {
          formData.append("commentAttachment", file);
        });
      else if (this.appraisal.commentData.commentAttachment.length > 0)
        commentObj.commentAttachment = this.appraisal.commentData.commentAttachment;
      formData.append("commentData", JSON.stringify(commentObj));

      /**
       * Merging the save or complete option here with wemlo invoice and options
       */
      formData.append("ifSave", ifSave);
      formData.append("options", JSON.stringify(this.appraisal.options));

      let response = await Axios.post(
        BASE_API_URL + "additionalTask/appraisal",
        formData);

      this.$snotify[
        response.data.status == 200
          ? "success"
          : response.data.status == 403
          ? "info"
          : "error"
      ](response.data.message);

      if (response.data.status == 200) {
        this.saveEvent();
      }
      EventBus.$emit('closeWeLearn',false); // Closing learn more video modal;

      if (!ifSave) {
        this.$router.push({ path: "dashboard" });
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.$store.state.wemloLoader = false;
    }
  }

  /**Display doucment funcction  */
  public changeImageIndex(check) {
    try {
      this.dotLoader = true;
      if (check == "next") {
        this.fileIndex++;
        this.showPrev = true;
        this.fileToDisplay = this.fileArray[this.fileIndex];
        this.fileType = this.fileToDisplay
          .split(".")
          .pop()
          .toLowerCase();
        if (this.fileArray.length == this.fileIndex + 1) {
          this.showNext = false;
        }
      }
      if (check == "previous") {
        this.fileIndex--;
        this.showNext = true;
        this.fileToDisplay = this.fileArray[this.fileIndex];
        this.fileType = this.fileToDisplay
          .split(".")
          .pop()
          .toLowerCase();
        if (this.fileIndex == 0) {
          this.showPrev = false;
        }
      }
      this.fetchDocument(this.fileToDisplay, this.fileType);
    } catch (error) {
      console.log(error);
    }
  }
  /**************************************************************************************************
   *                        FUNCTION FOR DISPLAYING IMAGE DOCUMENTS                                  *
   **************************************************************************************************/
  public documentDisplay(files) {
    try {
      this.fileArray = [];
      files.forEach(d => {
        let file = d.path;
        this.fileArray.push(file);
      });
      this.fileIndex = 0;
      this.fileArray.length > 1
        ? (this.showNext = true)
        : (this.showNext = false);
      this.fileToDisplay = this.fileArray[this.fileIndex];
      this.fileType = this.fileToDisplay
        .split(".")
        .pop()
        .toLowerCase();
      this.fetchDocument(this.fileToDisplay, this.fileType);
    } catch (error) {
      console.log(error);
    }
  }

  private async fetchDocument(path, ext) {
    try {
      if (!['pdf','jpg','jpeg','png'].includes(ext)) return;
      let response = await Axios.post(BASE_API_URL+'common/fetchDocument', { path: path });
      let dom = document.getElementById('documentElement');
      let childElement = document.createElement(ext!='pdf'?'img': 'iframe');
      if (ext === 'pdf') {
        response.data = 'data:application/'+ext+';base64,'+response.data;
      } else {
        response.data = 'data:image/'+ext+';base64,'+response.data;
      }
      childElement.src = response.data;
      dom.appendChild(childElement);
    } catch (error) {
      console.log(error);
    }
  }

 async beforeMount() {
    this.loanTxnId = this.$route.query.id;
    await this.fetchAppraisalTaskDetail();
  }
}
