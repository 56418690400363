

import { Component } from "vue-property-decorator";
import AddComment from "@/views/AddComment.vue";
import { OrderTitle } from "@/models/tasks.model";
import { BASE_API_URL,EventBus } from "@/config";
import Axios from "axios";

import DisplayFiles from "@/views/DisplayDocument.vue";
import PipeLineSocketMixin from "@/mixins/PipeLineSocketMixin"
import CommonMixin from "@/mixins/common.mixin";
import { mixins } from "vue-class-component";

@Component({ components: { AddComment,DisplayFiles } })
export default class orderTitle extends mixins(PipeLineSocketMixin, CommonMixin) {
  public orderTitleData = new OrderTitle();
  public pipeLineHeaderData: any = {};
  public loanTxnId = null;
  public manualEmailSent = true;
  private loanVersion = '';
  public taskNameForRoom = 'order-title';

  private playWeLearnVideo() {
    EventBus.$emit("openWeLearn", { taskName: "Order Title", videoUrl: 'https://wemlo.s3.amazonaws.com/Common/taskdemo/TitleOrder.mp4' });
  }
  public async fetchOrderTitleTaskDetail() {
    try {
      this.$store.state.wemloLoader = true;
      let response = await Axios.post(
        BASE_API_URL + "additionalTask/fetchOrderTitleTaskDetails",
        { loanTxnId: this.loanTxnId });

      this.pipeLineHeaderData = response.data.pipeLineHeaderData;
      this.orderTitleData = response.data.orderTitle;
      this.loanVersion = this.pipeLineHeaderData.loanVersion;
      this.$store.state.wemloLoader = false;
    } catch (error) {
      console.log(error);
      this.$store.state.wemloLoader = false;
    }
  }

  public async retryOrderPlace(){
    try{
      this.$store.state.wemloLoader = true;
      let response = await Axios.post(BASE_API_URL + "titleOrder/orderPlaceForTitleCompany", 
      {
        loanTxnId: this.loanTxnId,
        userType: JSON.stringify(this.$store.state.sessionObject.type)
      });
      this.$store.state.wemloLoader = false;
      
      if(response.data.status == 200 && !response.data.error){
        this.$snotify["success"](response.data.message);
        await this.fetchOrderTitleTaskDetail();
      }else if(response.data.error){
        this.$snotify["error"](response.data.error);
      }
    }catch(e){
      console.log(e);
      this.$store.state.wemloLoader = false;
    }
  }

  public async submit(ifSave) {
    try {
      ifSave = ifSave == "Save" ? true : false;
      let validComment = await this.$refs.comment["validateComment"](ifSave);

      if (
        (ifSave ? false : !(await this.$validator.validateAll())) ||
        !validComment
      )
        return;
      this.$store.state.wemloLoader = true;
      let formData = new FormData();
      formData.append("loanTxnId", this.loanTxnId);

      /**
       * Appending comment data and comment attachments here in formdata
       */
      let commentObj: any = {
        comment: this.orderTitleData.commentData.comment,
        showCommentToBroker: this.orderTitleData.commentData && this.orderTitleData.commentData.showCommentToBroker
      };
      if (
        this.orderTitleData.commentData.commentAttachment.length > 0 &&
        !this.orderTitleData.commentData.commentAttachment[0].hasOwnProperty(
          "path"
        )
      )
      
        this.orderTitleData.commentData.commentAttachment.forEach(file => {
          formData.append("commentAttachment", file);
        });
      else if (this.orderTitleData.commentData.commentAttachment.length > 0)
        commentObj.commentAttachment = this.orderTitleData.commentData.commentAttachment;
      formData.append("commentData", JSON.stringify(commentObj));

      /**
       * Merging the save or complete option here with wemlo invoice and options
       */
      formData.append("ifSave", ifSave);
      formData.append("options", JSON.stringify(this.orderTitleData.options));

      let response = await Axios.post(
        BASE_API_URL + "additionalTask/orderTitle",
        formData);

      this.$snotify[
        response.data.status == 200
          ? "success"
          : response.data.status == 403
          ? "info"
          : "error"
      ](response.data.message);

      if (response.data.status == 200) {
        this.saveEvent();
      }

      EventBus.$emit('closeWeLearn',false); // Closing learn more video modal;

      if (!ifSave) {
        this.$router.push({ path: "dashboard" });
      }
    } catch (error) {
      console.log(error);
      const infectedFiles = this.checkResponseIfFileInfected(error);
      infectedFiles.forEach(filename => {
        this.removeInfectedFile(filename)
      })
    } finally {
      this.$store.state.wemloLoader = false;
    }
  }

  public removeInfectedFile(filename: string): void {
    // check if comment attachment contains infected filename
    if (
      this.orderTitleData.commentData.commentAttachment.length > 0 &&
      !this.orderTitleData.commentData.commentAttachment[0].hasOwnProperty(
          "path"
      )
    )
    {
      this.orderTitleData.commentData.commentAttachment = this.orderTitleData.commentData.commentAttachment.filter(file => (file.name !== filename));
    }
  }

  displayOrderTitleFile(){
    let orderFile = this.orderTitleData['attachments'] && this.orderTitleData['attachments'][0]
     this.$refs.dfl["setInitialValuesForAttachmentsAndComments"](
      null,
      [orderFile],
      null
    );
  }

  async mounted() {
    this.loanTxnId = this.$route.query.id;
    this.fetchOrderTitleTaskDetail();
  }
}
